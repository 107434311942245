import { IDspEnvironment } from '@models/interfaces/environment';

export const environment: IDspEnvironment = {
  production: true,
  secure: true,
  apiVersion: 'v4',
  apiHost: 'https://api.docsnyderspage.com',
  host: 'https://www.docsnyderspage.com',
  key: 'PROD_LIVE',
};

// MBPAW

// export const environment = {
//   production: true,
//   apiVersion: 'v4',
//   apiHost: 'http://127.0.0.1:3000',
//   host: 'http://localhost:4200',
// };

// MBPSIL
// export const environment = {
//   production: true,
//   apiHost: 'http://192.168.178.99:3000',
//   apiUrl: 'http://192.168.178.99:3000/api/public',
//   host: 'https://localhost:4200',
//   domain: 'localhost',
// };
